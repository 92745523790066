import { merge } from "theme-ui";
import originalTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const codeFontSize = "0.7em";

const theme = merge(originalTheme, {
  fonts: {
    heading: `"futura-pt", Arial, sans-serif`,
    body: `"futura-pt", Arial, sans-serif`,
  },
  styles: {
    p: {
      fontSize: [2, 2, 3],
      code: {
        fontSize: codeFontSize,
      },
    },
    ul: {
      li: {
        fontSize: [2, 2, 3],
        code: {
          fontSize: codeFontSize,
        },
      },
    },
    ol: {
      li: {
        fontSize: [2, 2, 3],
      },
    },
    tr: {
      th: {
        fontSize: [1, 1, 2],
        code: {
          fontSize: codeFontSize,
        },
        paddingRight: "1rem",
      },
      td: {
        fontSize: [1, 1, 2],
        code: {
          fontSize: codeFontSize,
        },
      },
    },
    h1: {
      fontSize: [6, 6, 7, 8],
    },
    h2: {
      fontSize: [5, 6, 6, 7],
    },
    h3: {
      fontSize: [4, 5, 5, 6],
    },
    h4: {
      fontSize: [3, 4, 4, 6],
    },
    h5: {
      fontSize: [2, 3, 3, 4],
    },
    h6: {
      fontSize: 2,
    },
  },
  listItem: {
    fontSize: [2, 2, 3],
  },
});

export default theme;
